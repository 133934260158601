<script>
import { indicadorEstoque } from "@/components/defaults/indicadorestoque";
import Multiselect from "vue-multiselect";
import { http } from "@/helpers/easyindustriaapi/config";

export default {
  props: {
    currentEmpresa: { type: Object },
    unidades: { type: Array },
    locaisEstoques: { type: Array },
    contadores: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      select_blocoH:null,
      array_fin_arquivos: [
        {
          id: 0,
          nome: "Remessa do arquivo original",
        },
        {
          id: 1,
          nome: "Remessa do arquivo substituto",
        },
      ],
      select_fin_arquivo: null,
      array_perfies: [
        {
          id: 1,
          pes_id: "A",
          nome: "Perfil A",
        },
        {
          id: 2,
          pes_id: "B",
          nome: "Perfil B",
        },
        {
          id: 3,
          pes_id: "C",
          nome: "Perfil C",
        },
      ],
      select_perfil: null,
      contador_selecionado: null,
      select_contador: null,
      array_contadores: [],
      array_local_esto: [],
      select_unidade: null,
      array_unidades: [],
      fields: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Codigo",
          key: "codigoItem",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Local Estoque",
          key: "localEstoque",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Unidade",
          key: "unidade",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd produzida",
          key: "quantidadeProduzida",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd Estoque",
          key: "quantidadeEstoque",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Perdas",
          key: "perdas",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
          label: "Acões",
          key: "acoes",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      fields_k210: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Cod Produto",
          key: "codigoProduto",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Cod Insumo",
          key: "codigoInsumo",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd Consumida",
          key: "quantidadeConsumida",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "UN Consumida",
          key: "unidadeElaboracao",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd Result",
          key: "qtdResul",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        // {
        //   // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
        //   label: "Acões",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
      ],
      fields_k230: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Dt Ini",
          key: "DT_INI_OP",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Dt fim",
          key: "DT_FIN_OP",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Cod Ident",
          key: "COD_DOC_OP",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "COD Item",
          key: "COD_ITEM",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd Prod Acab",
          key: "QTD_ENC",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        // {
        //   // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
        //   label: "Acões",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
      ],
      fields_k235: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Dt Saida",
          key: "DT_SAÍDA",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Cod Item",
          key: "COD_ITEM ",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Quantidade",
          key: "QTD",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "COD isumo",
          key: "COD_INS_SUBST",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        // {
        //   // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
        //   label: "Acões",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
      ],
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0,
      },
      listIndicadores: [],
      loader: {
        get: false,
      },

      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas: [
        {
          id: 1,
          nome: "plano 01",
        },
        {
          id: 2,
          nome: "plano 02",
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      efd_objeto: {
        // inicio bloco0
        cod_fin: 0,
        dt_ini: null,
        dt_fin: null,
        nome: null,
        cnpj: null,
        uf: null,
        ie: null,
        cod_mun: null,
        im: null,
        suframa: null,
        ind_perfil: null,
        ind_ativ: null,
        // fim bloco0
        contador: {
          NOME: null,
          CPF: null,
          CRC: null,
          CNPJ: null,
          CEP: null,
          END: null,
          NUM: null,
          COMPL: null,
          BAIRRO: null,
          FONE: null,
          FAX: null,
          EMAIL: null,
          COD_MUN: null,
        },
      },
      plano: {
        woner_id: null,
        descricao: null,
        natureza: null,
        codigo_reduzido: null,
        classificacao: null,
      },
      blocoK: {
        K001: "",
        K100: {
          inicio: "",
          fim: "",
        },
        K200: [
          {
            data: "",
            codigoItem: "",
            localEstoque: "",
            // unidade: "",
            quantidadeProduzida: "",
            quantidadeEstoque: "",
          },
        ],
        K210: [
          { codigoProduto: "", codigoInsumo: "", quantidadeConsumida: "" },
        ],
        K215: [{ codigoItemdest: "", QtdDesc: "" }],
        K220: [
          {
            dataMovimentacao: "",
            codigoproduto: "",
            codigoEstoque: "",
            codigoInsumo: "",
            quantidadeConsumida: "",
          },
        ],
        K230: [
          {
            codigoprodutoacabado: "",
            quantidadeProduzida: "",
            codigoEstoque: "",
            codigoInsumo: "",
            unidadeMedida: "",
          },
        ],
        // K235: [
        //   { DT_SAÍDA: "",COD_ITEM: "", QTD:"", COD_INS_SUBST: ""},
        // ],
        // K250: [
        //   { DT_PROD: "",COD_ITEM: "", QTD:""},
        // ],
        // K255: [
        //   { DT_CONS: "",COD_ITEM: "", COD_INS_SUBST:""},
        // ],
        // K260: [
        //   { COD_OP_OS: "",COD_ITEM: "", DT_SAÍDA:"", QTD_SAÍDA:"", DT_RET:"", QTD_RET:""},
        // ],
        // K265: [
        //   { COD_ITEM: "", QTD_CONS:"", QTD_RET:"", DT_RET:"", QTD_RET:""},
        // ],
        // Continue com os outros registros (K220, K230, etc.) conforme necessário
      },
    };
  },
  created() {
    this.array_unidades = this.unidades;
    this.array_local_esto = this.locaisEstoques;
    this.array_contadores = this.contadores;
  },
  mounted() {
    this.$emit("newTitle", "Gerar Bloco K");
    this.localEstoque.empresa_id = this.currentEmpresa.id;
    this.listIndicadores = indicadorEstoque;
    console.log("Mostrando os dados da Empresa");
    console.log(this.currentEmpresa);
  },
  methods: {
    seleciona_blocoH(event){
       console.log(event)
    },
    seleciona_fin_arquivo(event) {
      console.log("selecionaodn finalidade Arquivo");
      console.log(event.target.value);
      if (event.target.value != -1) {
        let objeto_fin = this.array_fin_arquivos.find(
          (a) => a.id == event.target.value
        );
        console.log(objeto_fin);
        this.efd_objeto.cod_fin = objeto_fin.id;
      }
    },
    seleciona_perfil(event) {
      console.log("Selecionando perfil");
      console.log(event.target.value);
      if (event.target.value != -1) {
        let objeto_perfil = this.array_perfies.find(
          (p) => p.id == event.target.value
        );
        this.efd_objeto.ind_perfil = objeto_perfil.pes_id;
        console.log("Motrando perfil selecionado");
        console.log(this.efd_objeto.ind_perfil);
      }
    },
    seleciona_contador(event) {
      console.log("Mosntrado select contador");
      console.log(this.select_contador);
      console.log(event);
      let obj_cont = this.array_contadores.find(
        (c) => c.id == event.target.value
      );

      this.contador_selecionado = obj_cont;
      console.log("mostrando contador seleciando");
      console.log(this.contador_selecionado);
    },
    seleciona_estoque(valor) {
      console.log(valor);
    },
    seleciona_unidade(valor, index) {
      console.log(valor);
      console.log(index);
      console.log("Motraado Unidade Block 200");
      console.log(this.blocoK.K200[index].unidade);

      let select_unidade_objeto = this.array_unidades.find(
        (u) => u.id == this.blocoK.K200[index].unidade
      );
      console.log(select_unidade_objeto.DescricaoUn);
      this.blocoK.K200[index].unidade_string =
        select_unidade_objeto.DescricaoUn;
      console.log("Motraado Unidade Block 200");
      console.log(this.blocoK.K200[index]);
      // console.log(objeto)
    },
    gerarBlocoK() {
      console.log("Mostrando bloco K");
      // console.log(this.blocoK.K200[0])
      console.log("Mostrando bloco K200");
      if (this.blocoK.K200.length > 0) {
        this.blocoK.K200.forEach((e) => {
          console.log(e);
        });
      }
      if (this.blocoK.K210.length > 0) {
        this.blocoK.K210.forEach((e) => {
          console.log(e);
        });
      }
    },
    addK200() {
      this.blocoK.K200.push({
        data: "",
        codigoItem: "",
        localEstoque: "",
        unidade: "",
        unidade_string: "",
        quantidadeProduzida: "",
        quantidadeEstoque: "",
        perdas: "",
      });
    },
    removeK200(index) {
      this.blocoK.K200.splice(index, 1);
    },
    abr_multiselect(valor) {
      console.log("entrar");
      if (valor == 1) {
        console.log(valor);
        this.show_multiselect = true;
      } else {
        console.log("esntrou no elsse" + valor);
        this.show_multiselect = false;
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    converte_data() {
      console.log("convertendo data para o formato aceitavo para o Bloco EFD");
      let data_ini = this.efd_objeto.dt_ini;
      let dat_fin = this.efd_objeto.dt_fin;
      let separador1 = data_ini.split("-");
      let separador2 = dat_fin.split("-");
      console.log(data_ini);
      console.log(dat_fin);
      // dia / mes / ano - sendo zero o ano ,e assim sucessvamente;
      this.efd_objeto.dt_ini = separador1[2] + separador1[1] + separador1[0];
      this.efd_objeto.dt_fin = separador2[2] + separador2[1] + separador2[0];
      console.log("Mostrando o data final");
      console.log(this.efd_objeto.dt_fin);
      console.log("mosntrando data inicial");
      console.log(this.efd_objeto.dt_ini);
      // console.log("Mostrando resultado final da conversão")
      // console.log("data inicial")
      // console.log(this.efd_objeto.dt_ini)
      // console.log("data final")
      // console.log(this.efd_objeto.dt_fin)
    },

    async gerarBlocoK_bibli(efd) {
      console.log(this.efd_objeto.dt_ini);

      if (
        !(
          this.select_perfil != null &&
          this.select_perfil != undefined &&
          this.select_perfil != ""
        ) ||
        this.select_perfil == -1
      ) {
        this.makeToast("danger", "Selecione o Perfil");
      } else {
        if (
          !(
            this.select_contador != null &&
            this.select_contador != undefined &&
            this.select_contador != ""
          ) ||
          this.select_contador == -1
        ) {
          this.makeToast("danger", "Selecione O Contador");
        } else {
          if (
            !(
              this.efd_objeto.dt_ini != "" &&
              this.efd_objeto.dt_ini != null &&
              this.efd_objeto.dt_fin != "" &&
              this.efd_objeto.dt_fin != null
            )
          ) {
            if (
              this.efd_objeto.dt_ini == "" ||
              this.efd_objeto.dt_ini == null
            ) {
              this.makeToast("danger", "Preencha o valor da data inicial");
            } else {
              if (
                this.efd_objeto.dt_fin == "" ||
                this.efd_objeto.dt_fin == null
              ) {
                this.makeToast("danger", "Preencha o valor da data final");
              }
            }
          } else {
            this.converte_data();
            efd = { ...this.efd_objeto }; // clone do objeto
            efd.nome = this.currentEmpresa.razao;
            efd.cnpj = this.currentEmpresa.cnpj;
            efd.uf = this.currentEmpresa.uf;
            efd.ie = this.currentEmpresa.ie;
            efd.cod_mun = this.currentEmpresa.cidade_id;
            efd.FANTASIA = this.currentEmpresa.fantasia;
            efd.CEP = this.currentEmpresa.cep;
            efd.END = this.currentEmpresa.endereco;
            efd.NUM = this.currentEmpresa.numero;
            efd.BAIRRO = this.currentEmpresa.bairro;
            efd.FONE = this.currentEmpresa.fone;
            efd.EMAIL = this.currentEmpresa.email;
            efd.FAX = "0"; // no existe
            // efd.ind_perfil = "0"; // no existe
            if (this.contador_selecionado != null) {
              console.log(this.contador_selecionado);
              efd.contador.NOME = this.contador_selecionado.pes_apelido;
              if (this.contador_selecionado.pes_cpfcnpj.length == 11) {
                efd.contador.CPF = this.contador_selecionado.pes_cpfcnpj;
              } else if (this.contador_selecionado.pes_cpfcnpj.length > 11) {
                efd.contador.CNPJ = this.contador_selecionado.pes_cpfcnpj;
              }
              efd.contador.CRC =
                this.contador_selecionado.pes_crc != null
                  ? this.contador_selecionado.pes_crc
                  : "";
              efd.contador.CEP = this.contador_selecionado.pes_cep;
              efd.contador.END = this.contador_selecionado.pes_endereco;
              efd.contador.NUM = this.contador_selecionado.pes_numero;
              efd.contador.COMPL = this.contador_selecionado.pes_complemento;
              efd.contador.BAIRRO = this.contador_selecionado.pes_bairro;
              efd.contador.FONE =
                this.contador_selecionado.pes_celular != "" &&
                this.contador_selecionado.pes_celular != undefined
                  ? this.contador_selecionado.pes_celular
                  : this.contador_selecionado.pes_telefone != "" &&
                    this.contador_selecionado.pes_telefone != undefined
                  ? this.contador_selecionado.pes_telefone
                  : "";
              efd.contador.FAX = "";
              efd.contador.EMAIL =
                this.contador_selecionado.pes_email != "" &&
                this.contador_selecionado.pes_email != null
                  ? this.contador_selecionado.pes_email
                  : "";
              if (this.contador_selecionado.cidade_id != null) {
                efd.contador.COD_MUN = this.contador_selecionado.cidade_id;
              } else {
                this.makeToast("danger", "Codido do municipio não preenchido");
              }
            }
            // console.log("gerando")
            // efd.empresa_id = this.currentEmpresa.id;
            //this.currentEmpresa
            let response = await http
              .post("/nfe/blocoK", efd)
              .catch((error) => {
                this.makeToast("danger", error.response);
                //this.offLoader();
              });

            if (response.status === 200) {
              const texto = response.data;
              const blob = new Blob([texto], {
                type: "text/plain;charset=utf-8",
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = "blocoK.txt";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.makeToast("success", "Registro incluído");
            }
          }
        } // fim verificar contador
      } // fim verficar Perfil
    },
    setPost() {
      this.loader.get = true;
      setTimeout(function () {
        this.loader.get = false;
      }, 2000);
      this.$emit("doPost", this.plano);
    },
    selecionar_plano(event) {
      this.plano.woner_id = event.id;
    },
    selecionar_tipos(event) {
      this.plano.classificacao = event.id;
    },
    seleciona_orientacoes(event) {
      this.plano.natureza = event.id;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <!-- <h1></h1> -->
          <Label>{{ "Periodo de Apuração" }}</Label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 nt-4">
          <label for="">{{ "*Data inicial" }}</label>
          <input
            v-model="efd_objeto.dt_ini"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-6">
          <label for="">{{ "*Data Final" }}</label>
          <input
            v-model="efd_objeto.dt_fin"
            type="date"
            class="form-control text-left"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <Label>{{ "*Contador" }}</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_contador"
            @change="seleciona_contador($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in array_contadores"
              :key="index"
              :value="item.id"
            >
              {{ item.pes_apelido }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Perfil" }}</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_perfil"
            @change="seleciona_perfil($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in array_perfies"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Finalidade do Arquivo" }}</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_fin_arquivo"
            @change="seleciona_fin_arquivo($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in array_fin_arquivos"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <Label>{{ "*Gerar Bloco H ?" }}</Label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="select_blocoH"
            @change="seleciona_blocoH($event)"
          >
            <option value="-1">selecione um valor</option>
            <option
              v-for="(item, index) in array_fin_arquivos"
              :key="index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <div v-for="(k200, index) in blocoK.K200" :key="index">
            <h3>Registro K200 - Item {{ index + 1 }}</h3>
            <input
              v-model="k200.data"
              placeholder="Data de Movimentação (ddmmaaaa)"
              class="form-control text-left"
              type="date"
            />
            <input
              v-model="k200.codigoItem"
              placeholder="Código do Item"
              class="form-control text-left"
            />
            <input
              v-model="k200.localEstoque"
              placeholder="Código do Local de Estoque"
              class="form-control text-left"
            />
            <input
              v-model="k200.unidade"
              placeholder="Unidade de Medida"
              class="form-control text-left"
            />
            <input
              v-model="k200.quantidadeProduzida"
              placeholder="Quantidade Produzida"
              class="form-control text-left"
            />
            <input
              v-model="k200.quantidadeEstoque"
              placeholder="Quantidade em Estoque"
              class="form-control text-left"
            />
            <input
              v-model="k200.perdas"
              placeholder="Quantidade de Perdas"
              class="form-control text-left"
            />
            <button @click="removeK200(index)" class="btn btn-danger">
              Remover
            </button>
          </div>
          <button @click="addK200()" class="btn btn-secondary">
            Adicionar Registro K200
          </button>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-12">
          <!-- <b-table
            bordered
            hover
            :items="blocoK.K200"
            id="doc-itens"
            :fields="fields"
            responsive
          >
            <template #cell(data)="row">
              <input
                type="date"
                class="form-control text-left"
                v-model="row.item.data"
              />
            </template>
            <template #cell(codigoItem)="row">
              <input
                type="text"
                class="form-control text-left"
                v-model="row.item.codigoItem"
                placeholder="Digite"
              />
            </template>
            <template #cell(localEstoque)="row">
              <input
                type="text"
                class="form-control text-left"
                v-model="row.item.localEstoque"
                placeholder="Digite"
              />
            </template>
            <template #cell(unidade)="row">
              <input
                type="text"
                class="form-control text-left"
                v-model="row.item.unidade"
                placeholder="Digite"
              />
            </template>
            <template #cell(quantidadeProduzida)="row">
              <input
                type="text"
                class="form-control text-left"
                v-model="row.item.quantidadeProduzida"
                placeholder="Digite"
              />
            </template>
            <template #cell(quantidadeEstoque)="row">
              <input
                type="text"
                class="form-control text-left"
                v-model="row.item.quantidadeEstoque"
                placeholder="Digite"
              />
            </template>
            <template #cell(perdas)="row">
              <input
                type="text"
                class="form-control text-left"
                placeholder="Digite"
                v-model="row.item.perdas"
              />
            </template>
          </b-table> -->
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.K200 variant="info"
              >Produção/Estoques Acabados - K200</b-button
            >
          </b-card-header>
          <!-- visible -->
          <b-collapse id="K200" accordion="pedido-accordion" role="tabpanel">
            <!-- criar um b-table aqui depois -->
            <!-- <div class="row mt-4">
              <div class="col-md-3">
                <label for="">{{ "*Data Movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codito Item " }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codigo Local estoque" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Unidade de Medida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label for="">{{ "*Quantidade Produzida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Qtd Estoque apos a movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Quantidade de Perdas ou Quebras" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div> -->
            <b-table
              bordered
              hover
              :items="blocoK.K200"
              id="doc-itens"
              :fields="fields"
              responsive
            >
              <template #cell(data)="row">
                <input
                  type="date"
                  class="form-control text-left"
                  v-model="row.item.data"
                />
              </template>
              <template #cell(codigoItem)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.codigoItem"
                  placeholder="Digite"
                />
              </template>
              <template #cell(localEstoque)="row">
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="row.item.localEstoque"
                  @change="seleciona_estoque($event)"
                >
                  <option value="-1">selecione um valor</option>
                  <option
                    v-for="(item, index) in array_local_esto"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.descricao }}
                  </option>
                </select>
                <!-- <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.localEstoque"
                  placeholder="Digite"
                /> -->
              </template>
              <template #cell(unidade)="row">
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="row.item.unidade"
                  @change="seleciona_unidade($event, row.index)"
                >
                  <option value="-1">selecione um valor</option>
                  <option
                    v-for="(item, index) in array_unidades"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.DescricaoUn }}
                  </option>
                </select>
              </template>
              <template #cell(quantidadeProduzida)="row">
                <input
                  type="number"
                  class="form-control text-left"
                  v-model="row.item.quantidadeProduzida"
                  placeholder="Digite"
                  min="0"
                />
              </template>
              <template #cell(quantidadeEstoque)="row">
                <input
                  type="number"
                  class="form-control text-left"
                  v-model="row.item.quantidadeEstoque"
                  placeholder="Digite"
                />
              </template>
              <template #cell(perdas)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  placeholder="Digite"
                  v-model="row.item.perdas"
                />
              </template>
              <template #cell(acoes)="row">
                <!-- <button class="btn btn-info" @click.prevent="addK200(row.item)">add</button> -->
                <button
                  class="btn btn-danger"
                  @click.prevent="removeK200(row.item)"
                >
                  Del
                </button>
                {{ row.item.acoes }}
              </template>
            </b-table>
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-12 text-center">
                <button class="btn btn-info" @click.prevent="addK200()">
                  Adicioanr K200
                </button>
                <button
                  class="btn btn-info ml-2"
                  @click.prevent="gerarBlocoK()"
                >
                  gerar Block K
                </button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.K210 variant="info"
              >Produção/Estoques Acabados - K210</b-button
            >
          </b-card-header>
          <!-- visible -->
          <b-collapse id="K210" accordion="pedido-accordion" role="tabpanel">
            <!-- criar um b-table aqui depois -->
            <!-- <div class="row mt-4">
              <div class="col-md-3">
                <label for="">{{ "*Data Movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codito Item " }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codigo Local estoque" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Unidade de Medida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label for="">{{ "*Quantidade Produzida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Qtd Estoque apos a movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Quantidade de Perdas ou Quebras" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div> -->
            <b-table
              bordered
              hover
              :items="blocoK.K210"
              id="doc-itens"
              :fields="fields_k210"
              responsive
            >
              <template #cell(codigoProduto)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.codigoProduto"
                  placeholder="Digite"
                />
              </template>
              <template #cell(codigoInsumo)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.codigoInsumo"
                  placeholder="Digite"
                />
              </template>
              <template #cell(quantidadeConsumida)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeConsumida"
                  placeholder="Digite"
                />
              </template>

              <!-- <template #cell(unidade)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.unidade"
                  placeholder="Digite"
                />
              </template>
              <template #cell(quantidadeProduzida)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeProduzida"
                  placeholder="Digite"
                />
              </template>
              <template #cell(quantidadeEstoque)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeEstoque"
                  placeholder="Digite"
                />
              </template>
              <template #cell(perdas)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  placeholder="Digite"
                  v-model="row.item.perdas"
                />
              </template> -->
            </b-table>
          </b-collapse>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.K230 variant="info"
              >Produção/Estoques Acabados - K230</b-button
            >
          </b-card-header>
          <!-- visible -->

          <b-collapse id="K230" accordion="pedido-accordion" role="tabpanel">
            <!-- criar um b-table aqui depois -->
            <!-- <div class="row mt-4">
              <div class="col-md-3">
                <label for="">{{ "*Data Movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codito Item " }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codigo Local estoque" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Unidade de Medida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label for="">{{ "*Quantidade Produzida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Qtd Estoque apos a movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Quantidade de Perdas ou Quebras" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div> -->
            <b-table
              bordered
              hover
              :items="blocoK.K230"
              id="doc-itens"
              :fields="fields_k230"
              responsive
            >
              <template #cell(DT_INI_OP)="row">
                <input
                  type="date"
                  class="form-control text-left"
                  v-model="row.item.DT_INI_OP"
                  placeholder="Digite"
                />
              </template>
              <template #cell(DT_FIN_OP)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.DT_FIN_OP"
                  placeholder="Digite"
                />
              </template>
              <template #cell(COD_DOC_OP)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.COD_DOC_OP"
                  placeholder="Digite"
                />
              </template>

              <template #cell(COD_ITEM)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.COD_ITEM"
                  placeholder="Digite"
                />
              </template>
              <template #cell(QTD_ENC)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.QTD_ENC"
                  placeholder="Digite"
                />
              </template>
              <!--
              <template #cell(quantidadeProduzida)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeProduzida"
                  placeholder="Digite"
                />
              </template>
              <template #cell(quantidadeEstoque)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeEstoque"
                  placeholder="Digite"
                />
              </template>
              <template #cell(perdas)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  placeholder="Digite"
                  v-model="row.item.perdas"
                />
              </template> -->
            </b-table>
          </b-collapse>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.K235 variant="info"
              >Produção/Estoques Acabados - K235</b-button
            >
          </b-card-header>
          <!-- visible -->

          <b-collapse id="K235" accordion="pedido-accordion" role="tabpanel">
            <!-- criar um b-table aqui depois -->
            <!-- <div class="row mt-4">
              <div class="col-md-3">
                <label for="">{{ "*Data Movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codito Item " }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Codigo Local estoque" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Unidade de Medida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label for="">{{ "*Quantidade Produzida" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Qtd Estoque apos a movimentação" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
              <div class="col-md-3">
                <label for="">{{ "*Quantidade de Perdas ou Quebras" }}</label>
                <input type="text" class="form-control text-left" />
              </div>
            </div> -->
            <b-table
              bordered
              hover
              :items="blocoK.K235"
              id="doc-itens"
              :fields="fields_k235"
              responsive
            >
              <template #cell(DT_SAÍDA)="row">
                <input
                  type="date"
                  class="form-control text-left"
                  v-model="row.item.DT_SAÍDA"
                  placeholder="Digite"
                />
              </template>
              <template #cell(COD_ITEM)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.COD_ITEM"
                  placeholder="Digite"
                />
              </template>
              <template #cell(QTD)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.QTD"
                  placeholder="Digite"
                />
              </template>

              <template #cell(COD_INS_SUBST)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.COD_INS_SUBST"
                  placeholder="Digite"
                />
              </template>
              <!-- <template #cell(QTD_ENC)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.QTD_ENC"
                  placeholder="Digite"
                />
              </template> -->
              <!--
              <template #cell(quantidadeProduzida)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeProduzida"
                  placeholder="Digite"
                />
              </template>
              <template #cell(quantidadeEstoque)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  v-model="row.item.quantidadeEstoque"
                  placeholder="Digite"
                />
              </template>
              <template #cell(perdas)="row">
                <input
                  type="text"
                  class="form-control text-left"
                  placeholder="Digite"
                  v-model="row.item.perdas"
                />
              </template> -->
            </b-table>
          </b-collapse>
        </div>
      </div>
      <div class="row mb-4 mt-4">
        <div class="col-md-6">
          <label for="">{{ "*Orientação" }}</label>
          <multiselect
            v-model="select_orientacao"
            @select="seleciona_orientacoes($event)"
            :options="orientacoes"
            label="nome"
          >
          </multiselect>
        </div>
        <div class="col-md-6">
          <label for="">{{ "*Tipo" }}</label>
          <multiselect
            @select="selecionar_tipos($event)"
            v-model="select_tipo"
            :options="tipos"
            label="nome"
          >
          </multiselect>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mt-4">
        <div class="col-md-12 text-center">
          <b-form-checkbox @change="abr_multiselect($event)">
            Vincular a outro Plano
          </b-form-checkbox>
          <!-- <input type="text" class="form-control text-left"> -->
        </div>
      </div>
      <div class="row" v-if="show_multiselect">
        <div class="col-md-12">
          <multiselect
            v-model="select_plano_conta"
            :options="planos_contas"
            label="nome"
            @select="selecionar_plano($event)"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <!-- v-if="!loader.get" -->
        <button class="btn btn-light" @click.prevent="gerarBlocoK_bibli()">
          Salvar
        </button>
        <i
          v-if="loader.get"
          class="fa fa-spinner fa-4x fa-spin text-success"
        ></i>
      </div>
    </div>
  </div>
</template>